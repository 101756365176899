<template>
  <fragment>
    <div class="main_page">
      <app-navbar></app-navbar>
      <div class="page">
        <loading :active.sync="isPageLoading" :is-full-page="false"></loading>
        <div v-if="!isPageLoading">
          <div class="page-title">
            <h1>Roles</h1>
          </div>
          <div class="page-content">
            <form>
              <div class="form-group row">
                <label for="searchKeyword" class="col-sm-2 col-form-label">Search by Keyword</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" v-model="searchKeyword" />
                </div>
                <div class="col-sm-2">
                  <button v-if="$permissions().hasEditRolePermission" type="button" class="btn btn-primary" @click="newRole">
                    <i class="fas fa-plus-circle"></i> Add New
                  </button>
                </div>
              </div>
            </form>
            <ul class="list-group list-group-flush border-bottom">
              <li class="list-group-item d-flex px-2" v-for="role in searchLocalDataset(getRoles)" :key="role.id">
                <div class="mr-auto">
                  <p class="m-0">{{ role.name }}</p>
                  <p class="m-0 small-grey">{{ role.isMembershipType ? "Membership Type" : "Non Membership Type" }}</p>
                </div>
                <a role="button" v-if="$permissions().hasEditRolePermission" href="#"
                    class="d-flex justify-content-between align-items-center flex-column" title="Edit" @click.prevent="editRole(role.id)">
                  <img class="mb-1" src="@/assets/document.svg" alt />
                  <span class="small-grey">Edit</span>
                </a>
                <a role="button" v-if="$permissions().hasDeleteRolePermission" href="#"
                    class="d-flex justify-content-between align-items-center flex-column ml-3 role-delete-trigger" title="Delete" @click.prevent="deleteRole(role)">
                  <img class="mb-1" src="@/assets/red_x.svg" alt />
                  <span class="small-grey">Delete</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="bv-modal-role" size="xl" hide-header hide-footer :no-close-on-backdrop="true">
      <role></role>
    </b-modal>
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import orderBy from "lodash/orderBy";
import MessageBox from "@/utils/messagebox";
import Toast from "@/utils/toast";

export default {
  name: "configuration-roles",
  components: {
    "app-navbar": () => import('@/components/app-navbar.vue'),
    'role': () => import('@/views/configuration/components/role.vue')
  },
  data: function() {
    return {
      isPageLoading: true,
      searchKeyword: null
    };
  },
  computed: {
    ...mapGetters(["getPermissionGroups", "getRoles"]),
  },
  methods: {
    sortedPermissions(permissions) {
      return orderBy(permissions, "name");
    },
    newRole() {
      this.resetRole().then(() => {
        this.$bvModal.show("bv-modal-role");
      });
    },
    editRole(id) {
      this.fetchRole(id).then(() => {
        this.$bvModal.show("bv-modal-role");
      });
    },
    async deleteRole(role) {
      let { id, name } = role;
      let confirm = await MessageBox.confirm(this, "Remove Role", "Are you sure you want to remove role, " + name + "? This will remove any associated memberships.");

      if (confirm) {
        this.removeRole(id).then(() => {
          Toast.success(this, "Successfully removed role!");
        });
      }
    },
    searchLocalDataset(values) {
      if (!this.searchKeyword || this.searchKeyword === "") return values;

      return values.filter(i => i.name.includes(this.searchKeyword));
    },
    ...mapActions(["fetchClientSettings", "fetchPermissionGroups", "fetchRole", "fetchRoles", "removeRole", "resetRole"])
  },
  created: function() {
    this.fetchClientSettings();
    this.fetchRoles();
    this.fetchPermissionGroups().then(() => {
      this.isPageLoading = false;
    });
  }
};
</script>
